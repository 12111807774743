.tabList {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
}
.tabList li {
    background-color: #aaaaaa;
    border-left: 1px solid #aaaaaa;
    border-top: 1px solid #aaaaaa;
    border-right: 1px solid #aaaaaa;
    padding: 0px;
    float: left;
}
.tabList li a {
    display: block; 
    color: #000000;
    padding: 4px 20px;
}
.tabList li.tabActive {
    background-color: #eeeeee;
}
.tabDisplayArea {
    position: relative;
    border-left: 1px solid #aaaaaa;
    border-bottom: 1px solid #aaaaaa;
    border-right: 1px solid #aaaaaa;
    background-color: #eeeeee;
    color: #000000;
    padding: 6px;
}
/*.orgTenantLink {
    color: #000000;
}*/