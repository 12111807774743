.Form-Container{
    padding: 15px;
}

.container {
    grid-template-columns: [first] 2rem [second] 2rem;
    grid-template-rows: [row1-start] 25% [row1-end] 25%;
}

.SubmitButton {
    background-color: #dddddd;
    border-bottom: 1px solid #666666;
    border-left: 1px solid #666666;
    border-top: 1px solid #aaaaaa;
    border-right: 1px solid #aaaaaa;
    padding: 6px 10px 6px 10px;
    border-radius: 6px;
    cursor: pointer;
    margin: 4px;
    margin-right: 8px;
    padding: 5px;
    display: inline-block;
    color: #000000;
    font-weight: bold;
    transition: 0.4s;
}

.SubmitButton:hover {
    background-color: green;
    color: #ffffff;
    border-top: 1px solid #333333;
    border-right: 1px solid #333333;
}

.checkboxContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
}

.float-left{
    float: left;
    font-weight: bold;
}

.float-right{
    float: right;
}

.input-container{
    width:fit-content;
    min-width: 95px;
    height: 2em;
}

.checkBox{
    margin-top: 13px;
    float:right;
}


.bold {
    font-weight: bold;
}

.DeleteButton {
    float: right;
}

.flex-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
}
.flex-item{
    width: 50%;
    min-width: 400px;
}

@media screen and (max-width: 800px) {
    .SubmitButton {
      padding: 16px;
      margin: 10px;
      margin-right: 12px;
    }
  }
    