.chart-container{
    margin-bottom: 20px;
    margin-top: 20px;
    width:50%;
    
}

.chart-wrapper{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    
}