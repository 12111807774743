h3
{
    margin: 2px;
}
.incidentTable
{
    border-spacing: 0px;
    border-collapse: collapse;
}
.incidentTable td, .incidentTable th
{
    border: 1px solid #222222;
    padding: 5px;
}
.incidentTable th
{
    background-image: none;
    background-repeat: repeat-x;
    background-color: #a20107;
    cursor: auto;
    vertical-align: top;
}
.incidentTable th.incidentTableHeader
{
    background-image: url("/Images/headerbg.png");
}
.noteTable
{
    border-spacing: 0px;
    border-collapse: collapse;
    border-bottom: 1px solid #222222;
}
.noteTable td, .noteTable th
{
    border: 0px;
}
.noteTable th
{
    background-repeat: repeat-x;
    background-color: #a20107;
}
.incidentNote
{
    border: 1px solid #222222;
    border-radius: 6px 0px 6px 0px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.noteDisplayArea
{
    padding: 8px;
}
.createIncidentHeader
{
    text-align: right;
    width: 180px;
}
.incidentSummaryInput
{
    width: 100%;
    height: 220px;
    box-sizing: border-box;
}
a.incidentRefLink
{
    font-weight: bold;
    color: #a20107;
}
.ql-editor
{
    height: 200px !important;
}
.filterDropdown
{
    margin-right: 12px;
    margin-bottom: 8px;
}
.pager
{
    display: flex;
}

.page
{
    font-weight: bold;
    border: 1px solid #222222;
    margin: 8px;
    width: 32px;
    height: 32px;
    border-radius: 5px;
}
.page a
{
    color: #222222;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.currentPage
{
    background-color: #a20107;
}
.currentPage a
{
    color: #ffffff;
}


.incidentSummary {
    max-width: 400px;
    max-height: 16px;
    overflow: hidden;
}
.incidentSummary-Project {
    max-width: 400px;
    overflow: hidden;
}
.incidentSummary-Hours {
    text-align:center
}