body,
html {
  font-family: 'Open Sans', sans-serif;
  background-color: #333333;
  color: #ffffff;
  font-size: 10pt;
  padding: 0px;
  margin: 0px;
  min-height: 100vh;
}

#root,
#NexusApp {
  min-height: 100vh;
}

#NexusApp {
  box-sizing: border-box;
  position: relative;
}

h1 {
  font-size: 22pt;
}

h2 {
  font-size: 18pt;
  margin: 8px;
  padding: 0px;
}

a {
  font-weight: bold;
  color: #a20107;
  text-decoration: none;
}

.whiteLink {
  color: #ffffff;
}

.hello {
  color: #ffffff;
}

.orgContainer {
  padding-top: 2px;
  padding-bottom: 2px;
}

.orgContainerInner p {
  padding: 0px;
  margin: 0px;
}

.actionLabel {
  display: inline-block;
  margin-right: 10px;
}

.nexusLinkCommon {
  padding: 3px;
  color: #a20107;
  text-decoration: none;
  cursor: pointer;
}

.nexusButtonCommon,
.nexusButtonCommonNoHover {
    background-color: #dddddd;
    border-bottom: 1px solid #666666;
    border-left: 1px solid #666666;
    border-top: 1px solid #aaaaaa;
    border-right: 1px solid #aaaaaa;
    padding: 6px 10px 6px 10px;
    border-radius: 6px;
    cursor: pointer;
    margin: 4px;
    margin-right: 8px;
    padding: 5px;
    display: inline-block;
    color: #000000;
    font-weight: bold;
    transition: 0.4s;
}

.nexusButtonCommon:hover,
.nexusButtonCommonToggle
{
  background-color: #a20107;
  color: #ffffff;
  border-top: 1px solid #333333;
  border-right: 1px solid #333333;
}

.nexusButtonCommon img {
  vertical-align: middle;
}

.loginForm table th {
  width: 110px;
}

.header {
    padding: 8px 20px 8px 0;
    color: #000000;
    background-color: #eeeeee;
    text-align: right;
    border-bottom: 2px solid #999999;
    height: 15px;
}

.headerControls {
  position: absolute;
  top: 4px;
  right: 8px;
}

.logo {
  float: left;
  background-color: #eeeeee;
  width: 230px;
  text-align: center;
  border-bottom-right-radius: 20px;
  margin-bottom: 2px;
  border-bottom: 2px solid #999999;
}

.lstTenant {
  margin-left: 8px;
  margin-right: 8px;
}

.leftColumn {
  float: left;
  width: 230px;
  color: #000000;
  clear: both;
}

.rightColumn {
  padding-left: 238px;
  padding-right: 8px;
  padding-bottom: 10px;
}

.leftNav {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  background-color: #cccccc;
}

.leftNav li {
  border: 1px solid #eeeeee;
  border-left: 1px solid #333333;
  border-bottom: 1px solid #333333;
}

.leftNav li a {
  display: block;
  padding: 4px;
}

.leftNav li:first-child {
  border-radius: 0px 6px 0px 0px;
}

.footer {
    background-color: #999999;
    color: #000000;
    padding: 4px;
    clear: both;
    font-size: 8pt;
    height: 12px;
    position: relative;
    bottom: 0px;
    left: 0px;
    right: 0px;
}

.footerCopyRight {
    float: left;
}

.footerVersion {
    float: right;
}

.infoTable {
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.loginTable {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.infoTable td,
.infoTable th,
.loginTable td,
.loginTable th {
  padding: 4px;
  vertical-align: top;
  border: 1px solid #aaaaaa;
  background-color: #cccccc;
}

.infoTable th {
  text-align: right;
  padding-right: 8px;
  width: 200px;
}

.loginTable th {
  text-align: left;
  padding-right: 8px;
  width: 200px;
}

.imgSearch {
  vertical-align: middle;
  padding-right: 6px;
}

.Error {
  font-weight: bolder;
  color: #a20107;
  text-align: center;
}

.PaddedError {
  padding: 10px 4px;
}

.MissingTime {
  position: relative;
  margin-top: 20px;
  background-color: #cccccc;
  border: 1px solid #eeeeee;
  padding: 4px;
  max-height: 400px;
  overflow: auto;
}

.MissingTimeTable {
  width: 100%;
}

.MissingTimeTable td {
  padding-top: 4px;
  padding-bottom: 4px;
}

input[type=checkbox] {
  vertical-align: middle;
  position: relative;
  bottom: .08em;
}

@media screen and (max-width: 800px) {
  .leftColumn,
  .rightColumn {
    margin: 0px;
    padding: 0px;
  }

  .leftColumn {
    font-size: 14pt;
    width: 100%;
  }

  .rightColumn {
    padding: 0px;
    margin: 0px;
    clear: both;
    padding-top: 5px;
  }

  body {
    font-size: 12pt;
  }

  .loginForm table th {
    width: 120px;
  }

  .nexusButtonCommon, .nexusButtonCommonNoHover {
    padding: 16px;
    margin: 10px;
    margin-right: 12px;
  }
}

@media screen and (max-height: 700px) {
  .footer {
    position: static;
  }
}

/* SCROLLBAR WEBKIT */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

/* #useragent-root {
  z-index: 999;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: relative;
} */
