.peopleForm {
    width: 600px;
}
.peopleForm th {
    width: 200px;
}
.peopleForm input[type=text] {
    width: 400px;
}
.contactInfoPanel{
    padding-bottom:6px;
}
.contactInfoAdd{
    background: #f0f0f0 url(/59dd880ee584af0d82c8958bf2d150fe.png);
    background-position: 5px 5px;
    background-repeat: no-repeat;
    padding-left: 22px;
}
.contactInfo
{
    padding-top: 6px;
    padding-bottom: 6px;
    border-bottom: #aaaaaa;
    border-bottom-style: solid;
    border-bottom-width: 1px;
}
.contactInfoPanelHeading{
    margin-top: 6px;
}
.contactInfoPanelHeading label{
    font-weight: bold;
    font-size: 10pt;
    margin-right: 6px;
}