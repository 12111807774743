.align-left{
    text-align: left;
    
}

.align-right{
    text-align: right;
    margin-right:5%;
    
    font-weight: bold;
    min-width: 10%;
}

.align-right-wide{
    text-align: right;
    margin-right:5%;
    
    font-weight: bold;
    min-width: 27%;
}

.address-container{
    margin-top: 5px;
}



