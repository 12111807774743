.orgForm {
    width: 600px;
}
.orgForm th {
    width: 200px;
}
.orgForm input[type=text] {
    width: 400px;
}
@media screen and (max-width: 800px) {
    .orgForm input[type=text] {
        width: 100%;
    }   
    .orgForm {
        width: 100%;
    }
}