.telephony-tab-heading {
    margin-left: 10px;
    margin-top: 5px;
    font-size: 2em;
    font-weight: 600;
    display: inline-block;
}
.telephony-object-filters {
    padding: 0 1% 0 1%;
}
.telephony-object-filters label {
    vertical-align: middle;
    position: relative;
    bottom: 1px;
}
.telephony-object-filters input[type=checkbox] {
    vertical-align: middle;
    position: relative;
    bottom: 1px;
}
.telephony-import-export {
    display: inline-block;
    float: right;
    width: 50%;
    text-align: right;
}
.telephony-filepond-container {
    width: 30%;
    display: inline-block;
    vertical-align: middle;
}
.telephony-button-container{
    display:inline-block;
}
.telephony-filepond-container .filepond--root {
    margin-bottom: 0px !important;
}
.telephony-filepond-failure {
    min-width: 30%;
    max-width: 50%;
    display: inline-block;
    background-color: #ff4c4c;
    border-radius: .5em;
    min-height: 38px;
    vertical-align: middle;
    margin-top: 1em;
    margin-bottom: 1em;
}
.telephony-filepond-response {
    color: white;
    text-align: center;
    padding-top: .8em;
    padding-bottom: .8em;
}
.telephony-trunk-info textarea {
    width: 340px;
    height: 140px;
}
.telephony-trunk-info pre {
    margin: 0;
}