.nexusForm {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 2rem;
    width: 50vw;
    box-sizing: border-box;
    padding: 10px;
    background-color: #eeeeee;
    color: #000000;
    border-bottom: 1px solid #666666;
    border-left: 1px solid #666666;
    border-top: 1px solid #aaaaaa;
    border-right: 1px solid #aaaaaa;
    border-radius: 6px;
    box-shadow: 5px 4px #555555;
}
.loginForm {
    margin-bottom: 20px;
}
.nexusForm h2 {
    text-align: center;
}
.nexusForm table {
    width: 100%;
}
.nexusForm table th {
    text-align: right;
}
.nexusForm table th,
.nexusForm table td {
    padding: 6px;
}
.nexusFormSubmit {
    text-align: center;
}
.nexusButton {
    cursor: pointer;
    font-weight: bolder;
    padding: 6px;
}
.nexusButtonSmall {
    cursor: pointer;
    font-weight: bolder;
    padding: 3px;
}
.nexusDropDown {
    font-weight: bolder;
    padding: 3px;
}
.nexusForm input[type='text'],
.nexusForm input[type='password'],
.nexusForm select,
.nexusForm textarea {
    padding: 6px;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
}
.nexusFormSpacer {
    padding-top: 6px;
    padding-bottom: 6px;
}
.nexusAddTenant {
    border-radius: 50%;
    border: solid;
    width: 35px;
}
.loginLogo {
    text-align: center;
}

@media screen and (max-width: 800px) {
    .nexusForm input[type='text'], .nexusForm input[type='password'], .nexusForm select, .nexusForm textarea {
        padding: 8px;
        width: 100%;
        box-sizing: border-box;
    }
    .nexusForm input[type='checkbox'] {
        transform: scale(1.5);
    }
    .nexusFormSpacer {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .nexusForm {
        width: 100%;
        border-left: 0px;
        border-right: 0px;
        border-radius: 0px;
    }
}
