.ReactModal__Content input {
    box-sizing: border-box;
}

/* Nexus Modal (Uses React Modal) */

.nexus-modal label {
    color: black;
}

.nexus-modal {
    min-width: 360px;
}

.nexus-modal {
    color: black;
}