
.primary {
    color: #a20107;
}

.secondary {
    color: #cccccc;
}

.tertiary {
    color: #ffffff;
}

.adminHeader {
    background-image: url('/Images/headerbg.png');
    background-repeat: repeat-x;
    background-color: #a20107;
    color: #ffffff;
    font-weight: bold;
    text-align: left;
    padding: 5px 0;
}

.adminRow {
    font-weight: bold;
    text-align: left;
    padding: 5px 0;
    border-top: 1px solid #92000033;
    border-left: 2px solid #920000;
    margin: 0px auto;
}

.adminRow > * {
    margin-left: -1px;
}

.nested {
    margin: 0 auto;
    margin-right: 0;
    /* border-left: 1px solid #92000033; */
}

.nested.n0 {
    width: 100%;
}

.nested.n1 {
    width: 98%;
}

.nested.n2 {
    width: 98%;
}

.nested.n3 {
    width: 98%;
}

.nested.n4 {
    width: 98%;
}

.nested.n5 {
    width: 98%;
}

div.rolePersonHeader {
    background-color: #828282;
    color: #ffffff;
    font-weight: bold;
    padding: 2.5px 0;
}

div.rolePerson {
    padding: 5px 0;
    border-top: #d5d5d5 solid 2px;
}

button.link, button.header-link {
    border: none;
    background: none;
    font-weight: bold;
    white-space: nowrap;
}

button.link:hover {
    color:#a20107;
    text-decoration: underline;
}

button.header-link, button.header-link:hover {
    color: #ffffff;
}


#permissionList {
    margin: 10px 0;
    padding-right: 5px;
    max-height: 40vh;
    overflow-y: scroll;
}

#permissionContexts {
    font-family: monospace;
}

#permissionListModules {
    max-height: 30vh;
    overflow-y: scroll;
}

.empty-list {
    color: #a3a3a3;
    font-style: italic;
}

.nowrap {
    white-space: nowrap;
}

label {
    font-weight: bold;
}

.pr {
    padding-right: 5px;
}

.break {
    margin: 10px 0;
}

.permissionListItem.editing {
    box-shadow: inset 0 0 2px 2px #a2010766;
}

label.no-margin {
    margin-bottom: 0;
}

