.search-criteria{
    display:inline-block;
    height:120px;
    margin:1px;
}
    .search-criteria th {
        text-align: right;
    }
    .search-criteria input[type=text] {
        padding: 3px 6px;
        width: 90%;
    }
    .search-criteria select:not(.react-datetime-picker__inputGroup__input) {
        width: 100%;
        max-width:420px
    }
.search-criteria-footer{
    width:100%;
}
.search-criteria-footer-item{
    display:inline-block;
}
.search-criteria-footer-separator{
    display:inline-block;
    width:50%;
}
.search-criteria-footer-right {
    text-align: right;
}

.search-criteria-status {
    padding:5px;
}

.search-button {
    padding-left: 13px;
    padding-right: 13px;
}
.search-aggregate-container{
   margin-top:5px;
   margin-bottom:10px;
}
.search-aggregate {
    max-width: 70%;
    min-width: 50%;
    margin-right: auto;
    margin-left: auto;
    border-collapse: collapse;
}
.search-aggregate-footer {
    font-weight:900;
    font-size:1.1em;
}
.search-header-toggle {
    position: absolute;
    left: 25px;
    font-size: 1.75em;
}
.search-aggregate-header {
    background-color: #dddddd;
    border-bottom: 1px solid #666666;
    border-left: 1px solid #666666;
    border-top: 1px solid #aaaaaa;
    border-right: 1px solid #aaaaaa;
    text-align: center;
    padding: 10px;
    cursor: default;
    margin-bottom: 6px;
}
    .search-aggregate-header:hover {
        background-color: #333333;
        -webkit-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        cursor:pointer;
        color:white;
    }
.search-results-container{

}
.search-result-item {
    border-radius: 6px 0px 6px 0px;
    border: 1px solid #222222;
    margin-bottom: 6px;
}
.search-result-header {
    background-image: url(/Images/headerbg.png);
    background-repeat: repeat-x;
    background-color: #a20107;
    color: #ffffff;
    font-weight: bold;
    text-align: left;
    padding: 8px;
}

.search-result-header-left {
    display: inline-block;
    width: 50%;
}

.search-result-header-right {
    display: inline-block;
    width: 50%;
    text-align:right;
}
.search-result-info {
    font-size: .8em;
}
.search-result-info table tbody th {
    width:150px;
    background-color: #dddddd;
}
    .search-result-info table.infoTable tbody td {
        width: 150px;
        background-color: #dddddd;
    }
    .search-result-info table tbody td:last-child {
        width:auto;
    }
.search-result-info-separator {
    background-color: #a20107;
    color: #ffffff;
    padding:8px;
}
.search-result-info-separator a {
    color: #ffffff;
    font-size: 1em;
    margin-right:5px;
    cursor:pointer;
}
.search-result-summary{
    padding:11px;
}
.search-result-summary-separator {
    height: 20px;
    background-color: #dddddd;
}

.search-result-note{
    padding:11px;
}
.search-result-note-info-container{
    font-weight:700;
    margin-bottom:5px;
}
.search-result-note-info {
    display: inline-block;
    width: 50%;
}
.search-result-note-info-hours {
    display: inline-block;
    width: 50%;
    text-align:right;
}
    .search-result-note-info-hours table {
        width: 150px;
        display: inline-block;
    }
.search-result-note-summary {
}
.search-result-note-separator {
    background-image: url(/Images/headerbg.png);
    background-repeat: repeat-x;
    background-color: #a20107;
    color: #ffffff;
    min-height:12px;
}
.search-result-note-separator-options {
    display: inline-block;
    width: 50%;
}
.search-result-note-separator-credit {
    display: inline-block;
    width: 50%;
    text-align:right;
}
    .search-result-note-separator-credit button {
        font-size: 1em;
        margin-left: 0px;
        padding: 3px;
        border-radius: 0;
    }
    .search-result-note-separator-credit input {
        width:70px;
    }

.search-result-note-separator-option {
    display: inline-block;
}
.search-result-note-separator-option > *{
    vertical-align:middle;
}