.nextTable {
    width: 100%;
}
.nextTable th {
    background-image: url('/Images/headerbg.png');
    background-color: #a20107;
    border: 1px solid #666666;
    border-collapse: collapse;
    cursor: pointer;
    color: #ffffff;
}
.nextTable td {
    border: 1px solid #666666;
    border-collapse: collapse;
    color: #000000;
    padding: 2px;
}
.nextTable tr {
    background-color: #ffffff;
}
.nextTable tr:nth-child(odd) {
    background-color: #eeeeee;
}
.nextTable td.tblFilter {
    background-color: #a20107;
    text-align: center;
    color: #ffffff;
}
.nextTable td.tblFilter input {
    width: 80%;
}
.nextTable a {
    color: #000000;
    font-weight: normal;
}
.nextTable .actionLabel a {
    font-weight: bolder;
}
.nextTable .indentRow {
    padding-left: 15px;
}
.editPTO {
    /*margin-left: auto;
    display: block;*/
    text-align: right;
}
.nextSubTable {
    width: 100%;
    margin-bottom: 0px;
}
.nextSubTable th {
    background-image: none;
    background-color: #222222;
    color: #ffffff;
}
.nextSubTable tr {
    background-color: #ffffff;
}
.nextSubTable tr:nth-child(odd) {
    background-color: #aaaaaa;
}