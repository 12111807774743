.TargetSelectButton{
    margin-left: 20px;
}

.file .fa-file {
    display: inline-block;
}

.file .fa-file-download {
    display: none;
}

.file:hover .fa-file {
    display: none;
}

.file:hover .fa-file-download {
    display: inline-block;
}