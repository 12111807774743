.notification {
    height: 3rem;
    background-color: #1a8ed1;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 10%;
    align-items: center;
    color: white;
    animation: popup 2s ease-in forwards;
}

@keyframes popup {
    from {
        opacity: 0;
        transform: translateY(3rem);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.notification h2,
.notification p {
    font-size: 1rem;
    margin: 0;
}

.error {
    background-color: #690000;
}

.success {
    background-color: #1ad1b9;
}
