.AssetStatusDisplay{
    text-align: center;
    margin: auto;
    width: 75%;
}

.Label{
    font-weight: bold;
}

.Drive-Display .drive-Label{
    text-align:center;
    
}

.AssetStatusDisplay .bootstrap-wrapper .asset-status-progress-bar.progress {
    background-color: rgb(224, 224, 222);
    border-radius: 20px;
    height: 1.5rem;
}

.AssetStatusDisplay .bootstrap-wrapper div.progress-bar {
    background-color: rgb(67, 157, 66);
    font-weight: bold;
    font-size: 15px;
}
