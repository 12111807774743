.nexusGrid {
    background-color: lightgray;
    border-radius: 6px 0px 6px 0px;
    border-collapse: collapse;
}

.nexusGrid table {
    table-layout: fixed;
    width: 100%;
    border: none;
}

.nexusGrid table .gridHeader,
.nexusGrid table .gridRow td {
    padding: 0.5rem;
}

.nexusGrid table .gridRow .gridCell a {
    color: #000000;
    font-weight: normal;
}

.nexusGrid .pagination {
    padding: 5px 15px 4px 15px;
}

.nexusGrid .pagination .dropdown {
    display: inline-block;
}

.nexusGrid .pagination .switch-pages button {
    background-image: url(/Images/headerbg.png);
    background-color: #a20107;
    margin: 2px;
    border-collapse: collapse;
    cursor: pointer;
    color: #ffffff;
    padding: 5px;
    background-size: contain;
    border-radius: 6px 0px 6px 0px;
    font-weight: bold;
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
}

.nexusGrid .form-control::placeholder {
    opacity: 0.7;
    font-style: italic;
}

.nexusGrid button[disabled] {
    opacity: 0.7;
    cursor: default;
}

.nexusGrid table tr th.gridHeader {
    background-image: url('/Images/headerbg.png');
    background-color: #a20107;
    border-bottom: none !important;
    border-bottom-width: 0;
    border: 1px solid black;
    border-collapse: collapse;
    cursor: pointer;
    color: #ffffff;
    background-size: contain;
    border-left: none;
    border-top: none;
    border-right: none;
}

.nexusGrid table tr th.checkboxCol,
.nexusGrid table tr th.radioCol {
    width: 35px;
}


.nexusGrid td.gridHeader {
    border: black;
    border-collapse: collapse;
}

.nexusGrid .gridHeader:first-child {
    border-radius: 6px 0px 0px 0px;
}

.nexusGrid .gridRow td {
    border: 1px solid black;
    border-collapse: collapse;
    color: #000000;
}

.nexusGrid .gridRow:nth-child(odd) {
    background-color: #eeeeee;
}
.nexusGrid tr.gridRow:hover {
    background-color: #c1c1c1;
}

.gridSortIcon {
    padding-left: 3px;
    padding-right: 3px;
    margin-top: 2px;
    margin-bottom: 4px;
}

.gridTotals {
    color: black;
    font-weight: bold;
    font-size: 1.1em;
    margin-right: 10px;
    vertical-align: middle;
}

td.gridColumnWrap {
    overflow: visible !important;
    white-space: normal !important;
    text-overflow: unset !important;
}
