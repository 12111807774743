.dashboardWidgetContainer
{
    position: relative;
    border-radius: 6px 0px 6px 0px;
    border: 1px solid #222222;
    margin-bottom: 12px;
}
.widgetHeader
{
    background-image: url('/Images/headerbg.png');
    background-repeat: repeat-x;
    background-color: #a20107;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    padding: 8px;
}
.widgetBody
{
    display: flex;
    overflow: auto;
}
.react-calendar__tile--active
{
    background-color: #a20107;
}
.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus
{
    background-color: #a20107;
}
.paddedBold
{
    padding: 8px;
    font-weight: bold;
}
.dashboardAlignRightContainer
{
    width: 100%;
    text-align: right;
    box-sizing: border-box;
    padding: 10px;
}

@media screen and (max-width: 800px) {
    .widgetBody 
    {
        flex-wrap: wrap;
    }
}